import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { motion, AnimatePresence } from "framer-motion"
import { useMedia } from "react-use"
import classNames from "classnames"
import Logo from "../icons/logo"
import InstagramIcon from "../icons/instagram"
import MenuButton from "../buttons/menu"
import CloseButton from "../buttons/close"

import "./header.less"

const NavLinks = () => {
  return (
    <>
      <li key="recipes">
        <Link to="/recipes">Recipes</Link>
      </li>
      <li key="shop">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://kitchenpan.bigcartel.com/"}
        >
          Shop
        </a>
      </li>
      <li key="about">
        <Link to="/about">About</Link>
      </li>
      <li key="contact">
        <Link to="/contact">Contact</Link>
      </li>
      <li key="instagram" className="instagram_icon list_icon">
        <InstagramIcon />
      </li>
    </>
  )
}

const Menu = ({ onClose }) => {
  return createPortal(
    <motion.div
      role="presentation"
      className="header__menu"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <ul>
        <li key="close-button" className="list_icon">
          <CloseButton onClick={onClose} />
        </li>
        <li key="home">
          {location.pathname === "/" ? (
            <a onClick={onClose}>Home</a>
          ) : (
            <Link to="/">Home</Link>
          )}
        </li>
        <NavLinks />
      </ul>
    </motion.div>,
    document.body
  )
}

const HeaderMenu = ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add("menu-open")
    } else {
      document.body.classList.remove("menu-open")
    }

    return () => {
      document.body.classList.remove("menu-open")
    }
  }, [open])

  return <AnimatePresence>{open && <Menu onClose={onClose} />}</AnimatePresence>
}

const MobileNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  return (
    <>
      <ul>
        <li key="header-instagram" className="list_icon">
          <InstagramIcon />
        </li>
        <li key="open-menu" className="list_icon">
          <MenuButton
            onClick={() => {
              setDrawerIsOpen(true)
            }}
          />
        </li>
      </ul>
      <HeaderMenu open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)} />
    </>
  )
}

const Navigation = () => {
  const showMobileNavigation = useMedia("(max-width: 960px)", false)
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    setShouldDisplay(true)
  }, [])

  if (!shouldDisplay) {
    return null
  }

  return (
    <nav>
      {showMobileNavigation && <MobileNavigation />}
      {!showMobileNavigation && (
        <ul>
          <NavLinks />
        </ul>
      )}
    </nav>
  )
}

const Header = ({ secondaryHeader }) => {
  return (
    <header>
      <Logo />
      <Navigation />
    </header>
  )
}

Header.propTypes = {
  secondaryHeader: PropTypes.bool,
}

Header.defaultProps = {
  secondaryHeader: false,
}

export default Header
